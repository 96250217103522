import { checkMobileDevice } from './functions';
import { copyToClipboard } from './helpers';

const general = {
    init: function () {
        document.addEventListener("DOMContentLoaded", () => {
            this.placeholders();
            this.customScrollBar();
            this.fixSwipeIosSlider();
            this.navMenuAccordion();
            this.mainMenuShow();
            this.phoneMask();
            this.fixHeightMobile();
            this.langSwitcher();
            this.tooltips();
            this.expertPopup();
            this.officeSwitcher();
            this.copyLink();
            this.cookieBlock();
            this.sendGoalOnClick();
            this.setUtm();
            this.formSender();
            this.tileCategoriesTabs();
            this.tileCategoriesDropdown();
            this.copyContent();

            $(document).on('click', '[href="#"]', function(event) {
                event.preventDefault();
            });

            /**
             *Отправка целей в Яндекс.Метрика и Google Analytics
             * @param {string} [name] Имя цели
             * @param {string} [category] Категория цели
             * @param {function|null} [callback] Функция обратного вызова, срабатывает после отправки или не отправки всех целей.
             * */
            window.g_send_goal = function (name, category, callback) {
                var callback_i = 0;
                var callback_t = null;

                function callback_proc(is_finish) {
                    if (!callback) return;
                    if (callback_i >= 1 || is_finish) {
                        clearTimeout(callback_t);
                        callback();
                        callback = null;
                    }
                    callback_i++;
                }

                // Если категорию из lidtype не регистрирует Google Analytics
                if (category === "presentation") {
                    switch (name) {
                        case "present-index-complex":
                        case "catalog-first-page":
                        case "catalog-blog":
                            category = "download";
                            break;
                    }
                }

                try {
                    console.log("g_send_goal " + name + " " + category);
                    callback_t = setTimeout(
                        callback_proc.bind(this, true),
                        5000
                    );
                    if (typeof ym != "undefined") {
                        var ym_id = Ya.Metrika2.counters()[0].id;
                        ym(ym_id, "reachGoal", name, null, function () {
                            console.log("ym ok");
                            callback_proc();
                        });
                    } else {
                        console.log("No init ym");
                    }

                    if (typeof gtag != "undefined") {
                        gtag("event", name, {
                            event_category: category,
                            event_callback: function () {
                                console.log("gtag ok");
                                callback_proc();
                            },
                        });
                    } else {
                        console.log("No init gtag");
                    }
                    if (typeof fbq !== "undefined") {
                        fbq("track", "Lead");
                    }
                } catch (e) {
                    callback_proc(true);
                    console.error(e.message);
                }
            };

            /**
             *Отправка целей в Яндекс.Метрика и Google Analytics
             * @param {string} [name] Имя цели
             * @param {string} [category] Категория цели
             * @param {function|null} [callback] Функция обратного вызова, срабатывает после отправки или не отправки всех целей.
             * */
            window.g_send_goal = function (name, category, callback) {
                var callback_i = 0;
                var callback_t = null;

                function callback_proc(is_finish) {
                    if (!callback) return;
                    if (callback_i >= 1 || is_finish) {
                        clearTimeout(callback_t);
                        callback();
                        callback = null;
                    }
                    callback_i++;
                }

                // Если категорию из lidtype не регистрирует Google Analytics
                if (category === "presentation") {
                    switch (name) {
                        case "present-index-complex":
                        case "catalog-first-page":
                        case "catalog-blog":
                            category = "download";
                            break;
                    }
                }

                try {
                    console.log("g_send_goal " + name + " " + category);
                    callback_t = setTimeout(
                        callback_proc.bind(this, true),
                        5000
                    );
                    if (typeof ym != "undefined") {
                        var ym_id = Ya.Metrika2.counters()[0].id;
                        ym(ym_id, "reachGoal", name, null, function () {
                            console.log("ym ok");
                            callback_proc();
                        });
                    } else {
                        console.log("No init ym");
                    }

                    if (typeof gtag != "undefined") {
                        gtag("event", name, {
                            event_category: category,
                            event_callback: function () {
                                console.log("gtag ok");
                                callback_proc();
                            },
                        });
                    } else {
                        console.log("No init gtag");
                    }
                    if (typeof fbq !== "undefined") {
                        fbq("track", "Lead");
                    }
                } catch (e) {
                    callback_proc(true);
                    console.error(e.message);
                }
            };
        });
    },

    windowMargin: ($(window).width() - $(".site-wrapp .container").width()) / 2,
    windowPadding: parseInt($(".site-wrapp .container").css("padding-left")),

    cookieBlock: function () {
        var cookieBlock = $("#cookie-block");

        if (typeof $.cookie("agree_cookie") == "undefined") {
            cookieBlock.addClass("show");
        }

        $("[name=cookie-agree]").on("click", function () {
            $.cookie("agree_cookie", "i_agree");
            cookieBlock.removeClass("show");
        });
    },

    fixHeightMobile: function () {
        if (checkMobileDevice() && $(".fix-height-mobile").length !== 0) {
            $(".fix-height-mobile").css("max-height", $(window).height());
            $("body").addClass("hidden");
        }
    },

    langSwitcher: function () {
        $('button[name="lang-switcher-button"]').on("click", function () {
            $(this).closest(".lang-switcher").toggleClass("active");
        });

        $(document).on("click", function (event) {
            if (!event.target.closest(".lang-switcher")) {
                $(".lang-switcher").removeClass("active");
            }
        });
    },

    selectionsUpdateBlocks: function (selection) {
        if (selection != null) {
            $("[data-selections-update]").each(function (index, element) {
                var block = $(element);
                var anyBlockShow = false;
                if (selection === "") {
                    block.addClass("hidden-block");
                } else {
                    var toUpdate = block
                        .attr("data-selections-update")
                        .split(".");
                    var data = selection;
                    toUpdate.forEach((element) => {
                        data = data[element];
                    });

                    var blockItem = block.find(
                        "[data-selection-item]:first-child"
                    );
                    var blockContainer = block.find(
                        "[data-selection-container]"
                    );
                    block
                        .find("[data-selection-item]:not(:first-child)")
                        .remove();

                    for (const key in data) {
                        var blockShow = true;
                        for (const section in data[key]) {
                            if (data[key][section].trim() === "") {
                                blockShow = false;
                                break;
                            }
                            blockItem
                                .find(`[data-update="${section}"]`)
                                .html(data[key][section]);
                        }
                        if (blockShow) {
                            anyBlockShow = true;
                            if (parseInt(key) !== data.length - 1) {
                                blockItem = blockItem.clone();
                                blockContainer.append(blockItem);
                            }
                        } else {
                            if (parseInt(key) === data.length - 1) {
                                blockItem.css("display", "none");
                            }
                        }
                    }
                    if (anyBlockShow) block.removeClass("hidden-block");
                }
            });
        }
    },

    phoneMask: function () {
        general.maskTel(".phone-mask");
        $(document).on("phone-mask", function () {
            general.maskTel(".phone-mask");
        });
    },

    tileCategoriesTabs: function () {
        const tabItems = document.querySelectorAll(
            ".tile-countries__tabs .tile-categories__tabs-item.tile-categories__tabs-item-clickable"
        );
        const tileRows = document.querySelectorAll(
            ".tile-categories__tile-types__row"
        );

        // Функция для переключения табов и рядов тайлов
        function switchTab(index) {
            // Удаляем класс "active" у всех элементов табов и рядов
            tabItems.forEach((item) => {
                item.classList.remove("active");
            });
            tileRows.forEach((row) => {
                row.classList.remove("active");
            });

            // Добавляем классы "active" для выбранного элемента таба и соответствующего ряда тайлов
            tabItems[index].classList.add("active");
            tileRows[index].classList.add("active");
        }

        // Добавляем обработчик события клика для каждого элемента таба
        tabItems.forEach((item, index) => {
            item.addEventListener("click", (e) => {
                e.preventDefault()
                switchTab(index);
            });
        });
    },
    tileCategoriesDropdown: function () {
        const dropBtn = $(".country-dropdown__drop-btn");

        dropdownChange(
            $(".country-dropdown__link.active"),
            $(".country-dropdown__link.active").index()
        );

        $(".country-dropdown__link.country-dropdown__link-clickable").click(function (e) {
            e.preventDefault();
            dropdownChange(this, $(this).index());
        });

        function dropdownChange(el, idx) {
            /* $(".country-dropdown__link").show();
             $(el).hide();*/
            dropBtn.find('span').text($(el).text());
            $("#country-dropdown__checkbox").prop("checked", false);
            $(".tile-rent-type__tabs").removeClass("active");
            $(".country-dropdown__link").removeClass("active");
            $(".country-dropdown__link").eq(idx).addClass("active");
            $(".tile-rent-type__tabs").eq(idx).addClass("active");
        }
    },

    maskTel: function (el) {
        if (!window.maskTel_k) {
            window.maskTel_k = 10;
        }
        window.maskTel_k--;
        if (window.maskTel_k <= 0) return;
        if (typeof $.masksLoad == "undefined") {
            setTimeout(function () {
                maskTel(el);
            }, 200);
        } else {
            window.maskTel_k = 10;
            var maskList = $.masksSort(phoneCode, ["#"], /[0-9]|#/, "mask");
            var maskOpts = {
                inputmask: {
                    definitions: {
                        "#": { validator: "[0-9]", cardinality: 1 },
                    },
                    clearIncomplete: false,
                    showMaskOnHover: false,
                    autoUnmask: false,
                    oncomplete: function () {
                        $(this)
                            .parents("form")
                            .find('input[name="name"]')
                            .focus();
                    },
                },
                match: /[0-9]/,
                replace: "#",
                list: maskList,
                listKey: "mask",
            };
            $(el).inputmasks(maskOpts);
        }
    },

    placeholders: function () {
        if ($(window).width() > 767) {
            $("[data-placeholder-desk]").attr(
                "placeholder",
                $("[data-placeholder-desk]").attr("data-placeholder-desk")
            );
        } else {
            $("[data-placeholder-mobile]").attr(
                "placeholder",
                $("[data-placeholder-mobile]").attr("data-placeholder-mobile")
            );
        }
    },

    select: function () {
        function getShortSum(arr) {
            var shortStr = {
                9: "МЛРД",
                6: "МЛН",
                3: "ТЫС",
            };
            var result = [];

            arr.forEach(function (item) {
                var nums = item.replace(/\D/g, "");
                switch (true) {
                    case nums >= 1e9:
                        result.push(shortNums(nums, 9));
                        break;
                    case nums >= 1e6:
                        result.push(shortNums(nums, 6));
                        break;
                    case nums >= 1e3:
                        result.push(shortNums(nums, 3));
                        break;
                    default:
                        result.push(nums + " " + currency);
                }
            });

            function shortNums(str, tail) {
                return (
                    str.split("").reverse().slice(tail).reverse().join("") +
                    " " +
                    shortStr[tail] +
                    " ₽"
                );
            }

            return result.join(" — ");
        }

        $("[data-select-head]").on("click", function () {
            var tBody = $(this).parent("[data-select-body]");
            if (tBody.hasClass("active")) {
                tBody.removeClass("active");
            } else {
                $("[data-select-body]").removeClass("active");
                tBody.addClass("active");
            }
        });

        $(document).on("click", function (event) {
            if (!event.target.closest("[data-select-body]")) {
                $("[data-select-body]").removeClass("active");
            }
        });

        $("[data-select-list] input").on("change", function () {
            var item = $(this).closest("div");
            $(item).closest("[data-select-body]").removeClass("active");
            $(item).closest("[data-select-body]").addClass("change");
        });
    },

    customScrollBar: function (scrollWrapp, scrollbarOff) {
        var scrollBlock = !scrollWrapp
            ? $(".scroll__wrapp.scroll-onload")
            : scrollWrapp;

        if (scrollBlock.length == 0 || scrollBlock.hasClass("ready")) return;

        scrollBlock.each(function (i, el) {
            if ($(el).hasClass("desktop-scroll") && $(window).width() < 768) return;
            if ($(el).hasClass("mobile-scroll") && $(window).width() > 767) return;

            var item = $(el).find(".scroll__container"),
                scrollThumb = $(el).find(".scrollbar__drag"),
                containerScroll = item,
                containerWidth = 0,
                innerWidth = 0,
                widthScrollBar = 0,
                percentInner = 0,
                maxScroll = 0,
                positionThumb = 0,
                scrollPosition = 0,
                cursorPosition = 0,
                cursorStart = 0,
                currentThumb = 0,
                moveThumb = false,
                maxThumbMove = 0,
                thumbCurrent = 0,
                childWidth = 0,
                controlls = $(el).find(".scroll__controlls"),
                resizeTimeout,
                windowMargin;

            if ($(item).hasClass('autoswipe')) {
                $(window).scroll(() => {
                    if (!$(item).hasClass('scroll') && $(window).scrollTop() + $(window).height() > $(item).offset().top + $(item).height() && $(window).scrollTop() + $(window).height() < $(item).offset().top + $(item).height() + 100) {
                        $(item).addClass('scroll');
                        $(item).animate({
                            scrollLeft: 50
                        }, 600, 'swing', (function() {
                            $(item).animate({
                                scrollLeft: 0
                            }, 200, 'linear')
                        }));
                    }
                });
            }

            if (scrollbarOff) {
                return true;
            }

            $(document).on("scroll-bar-resize", function () {
                updateScrollbarParams();
                updateScrollbarPosition();
            });

            scrollThumb.on("mousedown", function (e) {
                moveThumb = true;
                cursorStart = e.clientX;
                $("body").addClass("no-select");
            });

            $(document).on("mouseup", function () {
                if (moveThumb) {
                    currentThumb = getPositionX(scrollThumb);
                    moveThumb = false;
                    $("body").removeClass("no-select");
                }
            });

            $(document).on("mousemove", function (e) {
                if (moveThumb) {
                    var toThumb = currentThumb + cursorPosition,
                        toScroll = (toThumb * 100) / maxThumbMove;
                    cursorPosition = e.clientX - cursorStart;
                    thumbCurrent = currentThumb + cursorPosition;

                    switch (true) {
                        case thumbCurrent < 0:
                            thumbCurrent = 0;
                            break;
                        case thumbCurrent > maxThumbMove:
                            thumbCurrent = maxThumbMove;
                            break;
                    }

                    scrollThumb.css(
                        "transform",
                        `translateX(${thumbCurrent}px)`
                    );

                    containerScroll.scrollLeft(
                        ((innerWidth - containerWidth) / 100) *
                            Math.round(toScroll)
                    );
                }
            });

            $(window).on("resize", delayedFunction);

            function delayedFunction() {
                clearTimeout(resizeTimeout);
                resizeTimeout = setTimeout(function () {
                    $(document).trigger("scroll-bar-resize");
                }, 500);
            }

            function updateScrollbarParams() {
                windowMargin =
                    ($(window).width() - $(".site-wrapp .container").width()) /
                    2;
                innerWidth = windowMargin * 2;

                if(containerScroll.hasClass("no-padding")){
                    windowMargin = 0;
                }

                (childWidth =
                    item.children("div, article").outerWidth() +
                    parseInt(
                        item.children("div, article").last().css("margin-left")
                    )),
                    containerScroll.css({
                        "padding-left": windowMargin,
                        "padding-right": windowMargin,
                        "scroll-padding-left": windowMargin,
                        "scroll-padding-right": windowMargin,
                    });

                containerWidth = $(containerScroll).width();

                innerWidth =
                    typeof $(el).find(".scroll__inner").innerWidth() !==
                    "undefined"
                        ? $(el).find(".scroll__inner").innerWidth()
                        : 0;
                if (!innerWidth) {
                    item.children().each(function (index, el) {
                        innerWidth =
                            $(el).innerWidth() +
                            innerWidth +
                            parseInt($(el).css("margin-left"));
                    });
                }
                widthScrollBar = $(el).find(".scrollbar__row").innerWidth();
                percentInner = Math.round((containerWidth * 100) / innerWidth);
                scrollThumb.css("width", percentInner + "%");
                maxScroll = innerWidth - containerWidth;
                currentThumb = getPositionX(scrollThumb);
                maxThumbMove = widthScrollBar - scrollThumb.width();
            }

            containerScroll.on("scroll", function (event) {
                updateScrollbarPosition();
            });

            function updateScrollbarPosition() {
                scrollPosition =
                    (containerScroll.scrollLeft() * 100) / maxScroll;
                positionThumb =
                    (widthScrollBar / 100) * scrollPosition -
                    (scrollThumb.width() / 100) * scrollPosition;

                if (!moveThumb) {
                    scrollThumb.css(
                        "transform",
                        `translateX(${positionThumb}px)`
                    );
                    currentThumb = getPositionX(scrollThumb);
                }
            }

            function getPositionX(elem) {
                var transformValue = elem.css("transform");
                if (!transformValue || transformValue == "none") {
                    return 0;
                } else {
                    return parseInt(transformValue.split(",")[4]);
                }
            }

            controlls.on("click", "button", function (e) {
                if (item.hasClass("on-move")) return;
                if ($(this).hasClass("scroll__controlls-left")) {
                    sliderMoving(item, item.scrollLeft() - childWidth, 300);
                } else {
                    sliderMoving(item, item.scrollLeft() + childWidth, 300);
                }
            });

            $(el).removeClass("scroll-onload");
            $(el).addClass("ready");

            $(document).trigger("scroll-bar-resize");
        });

        function sliderMoving(slider, offset) {
            slider.addClass("on-move");
            slider.animate(
                {
                    scrollLeft: offset,
                },
                300,
                () => {
                    slider.removeClass("on-move");
                }
            );
        }
    },

    fixSwipeIosSlider: function (parent) {
        // фикс кривого свайпа flickity на ios15
        if (parent) {
            var tapArea = parent[0].querySelectorAll(".flickity-viewport");
        } else {
            var tapArea = document.querySelectorAll(".flickity-viewport");
        }
        var startX;
        startX = 0;
        for (var item of tapArea) {
            item.ontouchstart = function (e) {
                startX = e.touches[0].clientX;
            };
            item.ontouchmove = function (e) {
                if (
                    Math.abs(e.touches[0].clientX - startX) > 5 &&
                    e.cancelable
                ) {
                    e.preventDefault();
                }
            };
        }
    },

    navMenuAccordion: function () {
        if ($(".main-menu__nav-item--clickable").length == 0) return;

        $(".main-menu__nav-item--clickable span").on("click", function () {
            var item = $(this).closest($(".main-menu__nav-item--clickable"));

            if ($(item).hasClass("active")) {
                $(item)
                    .removeClass("active")
                    .find(".main-menu__nav-list")
                    .slideUp(300);
                $(".main-menu__nav-item").removeClass("no-active");
            } else {
                $(".main-menu__nav-item")
                    .removeClass("active")
                    .find(".main-menu__nav-list")
                    .slideUp(300);
                $(".main-menu__nav-item").addClass("no-active");
                $(item)
                    .addClass("active")
                    .removeClass("no-active")
                    .find(".main-menu__nav-list")
                    .slideDown(300);
            }
        });
    },

    mainMenuShow: function () {
        $(document).on("click", "[data-open-menu]", function () {
            $("#main-menu").addClass("show");
            if ($(window).width() < 768) {
                $("body").addClass("hidden");
                scrollLock.disablePageScroll($('.main-menu__nav')[0]);
            }
        });

        $("#main-menu").on("click", function (event) {
            if (
                !event.target.closest(".main-menu__body") ||
                event.target.closest(".main-menu__close")
            ) {            
                $(this).addClass("hidden");            
                setTimeout(() => {
                    $(this).removeClass("show hidden");
                    $("body").removeClass("hidden");
                    scrollLock.enablePageScroll($('.main-menu__nav')[0]);
                }, 300);
            }
        });
    },

    cards: function () {
        general.cardsSliderInit();
        function cardSetHeight() {
            if ($(window).width() > 767) {
                $(".card").each(function () {
                    var descHeight = $(this)
                            .find('[data-content="desc"]')
                            .outerHeight(),
                        neumsHeight = $(this)
                            .find('[data-content="nums"]')
                            .outerHeight();

                    if (
                        typeof descHeight == "number" &&
                        typeof neumsHeight == "number"
                    ) {
                        var maxHeight = Math.max(descHeight, neumsHeight);
                    }

                    $(this).find("[data-content]").css("height", maxHeight);
                });
            }
        }

        function stickersControl() {

            $('.card__images-tags').each(function () {
                var $this = $(this);
                if ($this.children().length === 0 && $(window).width() > 767) {
                    $this.siblings('.card__flags').css('display', 'flex');
                }
            });
        }

        cardSetHeight();
        stickersControl();

        $(document).on("updateFilters", function () {
            general.cardsSliderInit();
            cardSetHeight();
            stickersControl();
        });

        if ($(".card__title").length !== 0) {
            $(".card__title").each(function () {
                if ($(this).text().length > 22) {
                    $(this).addClass("small");
                }
            });
        }

        $(document).on("click", ".card__toggle button", function () {
            var card = $(this).closest(".card"),
                val = $(this).attr("data-value");

            card.find(".card__toggle button").removeClass("selected");
            $(this).addClass("selected");
            card.find(".card__inner").removeClass("show");
            card.find(`.card__inner[data-content=${val}]`).addClass("show");
        });

        $(document).on("click", ".card button[name='currency']", function () {
            var block = $(this).closest(".card__nums");
            block.find('button[name="currency"]').removeClass("active");
            $(this).addClass("active");
            block.find(`[data-currency]`).removeClass("show");
            block
                .find(`[data-currency="${$(this).data("value")}"]`)
                .addClass("show");
        });

        $(document).on("click", "[data-lot-render]", function (e) {
            var lotRender = $(this).data("lot-render");
            general.setCookie("lot_render", lotRender);
            toggleCardsView(lotRender);
        });

        toggleCardsView(general.getCookie("lot_render"));

        function toggleCardsView(toggle) {
            if (typeof toggle == "undefined") {
                if (!$(`[data-lot-render="0"]`).closest(".owners-block").length) {
                    $(`[data-lot-render="0"]`).addClass("active");
                }
                else {
                    $(`[data-lot-render="1"]`).addClass("active");
                }
                return;
            }
            if (toggle === 1) {
                $(".cards").addClass("cards_grid");
            } else {
                $(".cards").removeClass("cards_grid");
            }
            $(`[data-lot-render]`).removeClass("active");
            $(`[data-lot-render=${toggle}]`).addClass("active");
        }
    },

    getCookie: function (name) {
        var value = `; ${document.cookie}`;
        var parts = value.split(`; ${name}=`);
        if (parts.length === 2) {
            return parts.pop().split(";").shift();
        }
    },

    setCookie: function (name, cookie) {
        document.cookie = `${name}=${cookie}`;
    },

    tooltips: function () {
        if ($(".tooltip").length == 0) return;
        $(".tooltip").each(function () {
            var tooltip = $(this),
                timeToShow = tooltip.data("time-show") * 1000;

            setTimeout(function () {
                tooltip.addClass("show");
            }, timeToShow);
        });

        $(".tooltip__close").on("click", function () {
            $(this).closest(".tooltip").removeClass("show");
        });
    },

    expertPopup: function () {
        // попап эксперта
        var transitionPopup = 300,
            expertMin = $("#popup-expert").find(".popup-expert__broker-wrapp"),
            expertBig = $("#popup-expert").find(".popup-expert__body"),
            expertClose = $("#popup-expert").find(".popup-expert__close"),
            firstScreenHeight = $(".header").outerHeight(),
            scrollTop = 0,
            windowBottom = 0,
            popupShowFlag = false;

        $(window).scroll(function () {
            scrollTop = $(window).scrollTop();
            windowBottom = scrollTop + $(window).height();
            if (scrollTop > firstScreenHeight) return;

            switch (true) {
                case firstScreenHeight < windowBottom && !popupShowFlag:
                    togglePopupExpertMin("show");

                    setTimeout(function () {
                        if (popupShowFlag && !$.cookie('popup-expert-closed')) {
                            openExpertBox();
                        }
                    }, 7000);
                    popupShowFlag = true;
                    break;
                case firstScreenHeight > windowBottom && popupShowFlag:
                    expertBig.hide(transitionPopup);
                    expertBig.removeClass("show");
                    expertMin.hide(transitionPopup);
                    expertMin.removeClass("show");
                    popupShowFlag = false;
                    break;
            }
        });

        function openExpertBox() {
            if (!$(".popup-expert__body").hasClass("show")) {
                togglePopupExpertMin("hide");
                setTimeout(function () {
                    togglePopupExpertBig("show");
                }, transitionPopup);
            }
        }

        function togglePopupExpertMin(switched) {
            switch (switched) {
                case "show":
                    if (!$(expertBig).hasClass("show")) {
                        expertMin.show(transitionPopup);
                        expertMin.addClass("show");
                    }
                    break;
                case "hide":
                    expertMin.hide(transitionPopup);
                    expertMin.removeClass("show");
                    break;
                default:
                    return false;
            }
        }

        function togglePopupExpertBig(switched) {
            switch (switched) {
                case "show":
                    if (!$(expertMin).hasClass("show")) {
                        expertBig.show(transitionPopup);
                        expertBig.addClass("show");
                    }
                    break;
                case "hide":
                    expertBig.hide(transitionPopup);
                    expertBig.removeClass("show");
                    break;
                default:
                    return false;
            }
        }

        $(expertClose).on("click", function (event) {
            $.cookie('popup-expert-closed', 'true');
            togglePopupExpertBig("hide");
            setTimeout(function () {
                togglePopupExpertMin("show");
            }, transitionPopup);
        });

        $(expertMin).on("click", function (event) {
            togglePopupExpertMin("hide");
            setTimeout(function () {
                togglePopupExpertBig("show");
            }, transitionPopup);
        });
    },

    officeSwitcher: function () {
        $("button[data-office-switch]").on("click", function () {
            var currentOffice = $(this).data("office-switch");

            $("[data-office-switch]").removeClass("active");
            $(`[data-office-switch="${currentOffice}"]`).addClass("active");
        });
    },

    copyLink: function () {
        if ($("[data-copy-link]").length === 0) return;

        $("[data-copy-link]").on("click", function (ev) {
            ev.preventDefault();
            var copyLink = $(ev.target).data("copy-link"),
                tooltip = $(ev.target)
                    .closest("[data-link-btn]")
                    .find("[data-copy-tooltip]"),
                tooltipHidden = tooltip.data("copyTooltip"),
                tooltipText = tooltip.text();
            navigator.clipboard.writeText(copyLink).then(function () {
                tooltip.text("Ссылка скопрована");
                if (tooltipHidden === "hidden") {
                    tooltip.show();
                }
                setTimeout(() => {
                    if (tooltipHidden === "hidden") {
                        tooltip.hide();
                    }
                    tooltip.text(tooltipText);
                }, 1000);
            });
        });
    },

    sendGoalOnClick: function () {
        $("[data-click-goal]").on("click", function () {
            let action = $(this).data("click-goal"),
                category = $(this).data("click-goal-category");
            g_send_goal(action, category);
        });
    },

    setUtm: function () {
        function getSearchParams() {
            var paramsString = document.location.search,
                searchParams = new URLSearchParams(paramsString);
            return searchParams;
        }

        function getUtm() {
            var utmNames = [
                    "utm_source",
                    "utm_medium",
                    "utm_content",
                    "utm_term",
                    "utm_campaign",
                ],
                utm = "";
            for (var i = 0; i < utmNames.length; i++) {
                var utmValue = getSearchParams().get(utmNames[i]);
                if (utmValue) utm += `&${utmNames[i]}=` + utmValue;
            }
            return utm;
        }

        if (getUtm().length != 0) {
            $.cookie("utm", getUtm());
        }
    },

    formSender: function () {
        function reportError(data) {
            window.formErrorApi(data);
            alert(
                "Кажется, что-то пошло не так. Повторите попытку позднее, спасибо."
            );
        }

        $(document).on("submit", "[data-submit]", function (e) {
            e.preventDefault();
            let data = $(this).serialize();
            let form = $(this);
            let submitBtn = $(this).find('button[type="submit"]');
            data += $.cookie("utm") ?? "";

            $.ajax({
                url: $(this).attr("action"),
                method: $(this).attr("method") || "get",
                data: data,
                beforeSend: function () {
                    form.find(".error").remove();
                    form.find(".has-error").removeClass("has-error");
                    submitBtn.addClass("waiting");
                },
                complete: (jqXHR) => {
                    if (jqXHR.status === 422) {
                        //Обработка ошибок валидации для Laravel
                        var errors = jqXHR.responseJSON.errors;
                        for (var key in errors) {
                            if (!errors.hasOwnProperty(key)) continue;
                            var field = form.find('[name="' + key + '"]');
                            if (
                                field.length &&
                                field.attr("type") !== "hidden"
                            ) {
                                field.addClass("has-error"); // Помечаем поле не прошедшее валидацию
                                field.siblings(".error").html(errors[key][0]); //Выводим сообщение об ошибке валидации
                            } else {
                                this.reportError(data); //Если поле не найдено или имеет type hidden, то оповещаем об ошибке в чате
                                break;
                            }
                        }
                    } else {
                        if (
                            !jqXHR.responseJSON ||
                            !jqXHR.responseJSON.success
                        ) {
                            //При успешной отправке формы сервер должен отвечать: echo json_encode({"status":"form_sent_ok"}); Кроме status можно добавить другие данные
                            reportError(data);
                            return false;
                        }

                        if (
                            form.attr("data-goal") !== undefined &&
                            self.sendMetrics
                        ) {
                            var category =
                                form.attr("data-goal-category") !== undefined
                                    ? form.attr("data-goal-category")
                                    : form.find('input[name="lidtype"]').val();
                            g_send_goal(form.attr("data-goal"), category);
                        }

                        let urlRedirect = null;

                        if (
                            typeof jqXHR.responseJSON !== "undefined" &&
                            typeof jqXHR.responseJSON.redirect !== "undefined"
                        ) {
                            urlRedirect = jqXHR.responseJSON.redirect;
                            window.location.href = urlRedirect;
                        } else {
                            alert("Ваша заявка принята");
                        }
                    }
                },
            });
        });
    },
    faq: function() {
        $(document).on("click", ".faq__btn", function(event) {
            var faq = $(this).closest(".faq"),
                item = $(this).closest(".faq__item");

            if (item.hasClass("active")) {
                item.removeClass("active");
                item.find(".faq__answer").slideUp(300);
            } else {
                faq.find(".faq__item").removeClass("active");
                faq.find(".faq__item")
                    .find(".faq__answer")
                    .slideUp(300);
                item.addClass("active");
                item.find(".faq__answer").slideDown(300);
            }
        });

        $(".column__item").click(function(event) {
            $(this).animate({ height: 100 }, 300);
        });
    },
    copyContent: function() {
        if ($('[data-copy]').length === 0 || $('[data-copy-mobile]').length === 0) return;

        $('[data-copy]').on('click', function(e) {
            e.preventDefault();
            copyToClipboard($(this).text());
        });
        
        if ($(window).width() < 768) {
            $('[data-copy-mobile]').on('click', function(e) {
                e.preventDefault();
                copyToClipboard($(this).text());
            });
        }
    }
};

export default general;
